@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes.scss';

// framework component themes (styles tied to theme variables)
@import '../../../../node_modules/@nebular/theme/styles/globals';
@import '../../../../node_modules/@nebular/auth/styles/globals';

@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../node_modules/bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';


// install the framework and custom global styles
@include nb-install() {

	// framework global styles
	@include nb-theme-global();
	@include nb-auth-global();

	@include ngx-layout();
	// loading progress bar
	//   @include ngx-pace-theme();

	@include nb-overrides();
}

// style the side menu
.menu-sidebar {
	@media only screen and (max-width: 756px) {
		top: 0 !important;
	}

	z-index: 1045 !important;
	width: 0 !important;

	&.expanded {
		.scrollable {
			width: 400px;
			background: var(--card-background-color);
			;
		}
	}

	.main-container {
		@media only screen and (max-width: 756px) {
			top: 0 !important;
			width: 100%;
		}

		.scrollable {
			@media only screen and (max-width: 756px) {
				width: 100%;
			}

			padding: 0 !important;
			padding-top: 20px !important;

			.menu-items {
				padding-left: 30px;
				padding-right: 30px;

				.menu-item {
					border: none;
				}
			}
		}
	}
}

// style the cart icon
.cart {
	.position-top {
		top: -10px !important;
		right: -10px !important;
	}
}

.user-action {
	@media only screen and (max-width: 756px) {
		display: none !important;
	}
}

// style filter tags
.filter-container {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	.tag {
		padding: 3px 10px;
		border-radius: var(--border-radius);
		background-color: var(--card-menu-item-background-color);

		&.active {
			background-color: var(--button-filled-danger-background-color);
			color: white;
		}
	}
}

// STYLE THE PROFILE PICTURE

.user-profile {
	max-width: 100px;

	.badge {
		background-color: var(--badge-success-background-color);
		color: white;
		top: 0;
		right: 0;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.custom-input {
	input {
		box-shadow: var(--shadow);
	}
}

.title-bar {
	background-color: var(--header-background-color);
	box-shadow: var(--shadow);
}

// GLOBAL CLASSES
.justify-space-between {
	justify-content: space-between;
}

.items-center {
	align-items: center;
}

.no-border {
	border: none !important;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.font-weight-600 {
	font-weight: 600;
}

// style round items 
.rounds {
	list-style: none;
	/* Remove default bullets */
}

.rounds li::before {
	content: "";
	color: var(--color-info-default);
	font-weight: bold;
	display: inline-block;
	width: 7px;
	height: 7px;
	background: #3CB2FF;
	border-radius: 50%;
}


.state-tag {
	padding: 2px 10px;
	border-radius: var(--border-radius);
	font-size: 13px;
	font-weight: 600;

	&.accepted {
		border-color: var(--color-success-600);
		color: var(--color-success-600);
	}

	&.pending {
		border-color: var(--color-warning-default);
		color: var(--color-warning-default);
	}

	&.rejected {
		border-color: var(--color-danger-default);
		color: var(--color-danger-default);
	}

	&.completed {
		border-color: var(--color-warning-default);
		background-color: var(--color-warning-default);
		color: var(--color-control-default);
	}
}

.order-item {
	background: var(--card-menu-item-background-color);
	border-radius: var(--border-radius);
	box-shadow: var(--shadow);
}

a {
	i {
		font-size: 25px;
	}

	.nb-trash {
		&:hover {
			color: red;
		}
	}

	.nb-edit {
		&:hover {
			color: blue;
		}
	}
}

.placeholder-img {
	height: 50px;
	width: 50px;
	// background-color: #F7F8FC;
}

@media only screen and (max-width: 992px) {
	.no-padding {
		.item-body {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

nb-toast {
	width: auto !important;
}

.sidebar-close-button {
	@include nb-ltr(margin-right, 1.25rem);
	@include nb-rtl(margin-left, 1.25rem);
	text-decoration: none;
	color: nb-theme(text-hint-color);
	position: absolute;
	right: 0;

	nb-icon {
		font-size: 1.75rem;
	}
}
input[type="file"]::file-selector-button {
	border-radius: 4px;
	padding: 0 16px;
	height: 40px;
	cursor: pointer;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.16);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	margin-right: 16px;
	transition: background-color 200ms;
  }
  
  /* file upload button hover state */
  input[type="file"]::file-selector-button:hover {
	background-color: #f3f4f6;
  }
  
  /* file upload button active state */
  input[type="file"]::file-selector-button:active {
	background-color: #e5e7eb;
  }


  .spicy-ico {
	content: '';
  	background: url(../../../assets/category-icons/spicy.png);
  	width: 30px;
  	height: 30px;
  	margin-left: -20px;
  }