@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-card nb-list {
    @include nb-scrollbars(
      nb-theme(card-scrollbar-color),
      nb-theme(card-scrollbar-background-color),
      nb-theme(card-scrollbar-width));
  }

  .nb-theme-dark ng2-smart-table table tr td{
    color: gray !important;
  }

  .table, .area {
    color: nb-theme(text-basic-color) !important;
  }

  nb-layout-header nav {
	  box-shadow: none;
  }

  nb-layout .layout .layout-container .content .columns nb-layout-column {
	  padding: 0 !important;
  }

  nb-sidebar.expanded {
	  @media only screen and (max-width: 756px) {
		width: 100%;
	  }
  }
  .cdk-overlay-container {
	z-index: 1050;
  }

  nb-sidebar.compacted {
	width: 0;
  }
  nb-sidebar.compacted .main-container {
	width: 0;
  }
  nb-layout .layout .layout-container nb-sidebar.fixed, nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
	top: 0;
  }
  nb-layout-header.fixed ~ .layout-container nb-sidebar .main-container {
	height: 100%;
  }

  nb-sidebar.compacted.start.fixed ~ .content {
	margin-left: 0 !important;
  }


}
